

import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'
import { Form } from 'view-design'
import formRules from './team-group-rules'
import { getterUserInfo } from '@/store/index'
import teamGroupApi, { TeamGroup } from '@/api/team-group'

@Component({
  name: 'AddTeamGroupModal',
  components: {
  }
})
export default class AddTeamGroupModal extends Vue {
    isShow = false

    loading = false

    title = '添加小组'

    dataItem: TeamGroup = {
      name: '',
      memo: ''
    }

    ruleValidate = formRules

    showDlg () {
      this.isShow = true
    }

    closeDlg () {
      this.reset()
      this.isShow = false
    }

    reset () {
      const form = this.$refs.formRef as Form
      form.resetFields()
    }

    validateData () {
      this.loading = true
      const form = this.$refs.formRef as Form
      form.validate((valid) => {
        if (valid) {
          this.saveData()
        } else {
          this.$Notice.error(
            {
              title: '提示',
              desc: '数据校验失败，请检查！'
            }
          )
          this.loading = false
        }
      })
    }

    saveData () {
      this.dataItem.ownTeam = getterUserInfo().activedTeamId
      teamGroupApi.insertItem(this.dataItem)
        .then(res => {
          this.loading = false
          this.$Notice.success(
            {
              title: '提示',
              desc: '添加成功'
            }
          )
          this.closeDlg()
          this.$emit('successData')
        })
        .catch((err: Error) => {
          this.loading = false
          this.$Notice.error({
            title: '提示',
            desc: err.message
          })
        })
        .finally()
    }
}

