

import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'
import teamUserApi, { TeamUse } from '@/api/team-user'
import teamGroupMemberApi, { TeamGroupMember } from '@/api/team-group-member'
import { BaseModal } from '@/libs/modal-helper'
import { getterUserInfo } from '@/store'

@Component({
  name: 'AddMembersModal',
  components: {
  }
})
export default class AddMembersModal extends Vue implements BaseModal {
  // 显示状态
    visiable=false
    // 对话框状态
    loading = false

    /**
     * 组名称
     */
    @Prop({
      required: true,
      type: String
    })
    groupName!: string

    /**
     * 组id
     */
    @Prop({
      type: Number,
      required: true
    })
    groupId?: number

    // 当前用户的团队id
    teamId=getterUserInfo().activedTeamId

    tableData: Array<TeamUse> = []

    selectDataItems: Array<TeamUse> = []

    /// 已经选择的成员
    selectedMembers: Array<number>=[]

    // 对话框标题
    get title () {
      return `添加组(${this.groupName})成员`
    }

    created () {
      this.loading = true
      teamGroupMemberApi.queryTeamGroupMember(this.groupId!)
        .then((response) => {
          this.selectedMembers = response.data!.map((item) => item.teamUserId!)
          return response.data
        })
        .then(() => {
          return teamUserApi.curTeamUserList()
        })
        .then(res => {
          this.tableData = (res.data as Array<TeamUse>)
            .filter((item) => {
              return this.selectedMembers.findIndex(m => m === item.id) === -1
            })
        })
        .catch((err: Error) => {
          this.$Notice.error({
            title: '提示',
            desc: err.message
          })
        }).finally(() => {
          this.loading = false
        })
    }

    /**
   * 全部选中 或 单条选中
   * 列表选中的行
   */
    onSelectionChange (selection: any) {
      this.selectDataItems = selection.records
    }

    show (): void {
      this.visiable = true
    }

    close (): void {
      this.visiable = false
    }

    doSave (): void {
      if (this.selectDataItems.length === 0) {
        this.$Message.error('请选择一个要加入组的成员用户')
        return
      }
      this.loading = true
      teamGroupMemberApi.saveGroupMember({
        teamUserList: this.selectDataItems,
        groupId: this.groupId
      })
        .then(() => {
          this.$Notice.success(
            {
              title: '提示',
              desc: '添加组的成员用户成功'
            }
          )
          this.$emit('onOk', this.selectDataItems)
        })
        .catch((err: Error) => {
          this.$Notice.error({
            title: '提示',
            desc: err.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    }

    doCancel (): void {
      this.$emit('onCancel')
    }
}

