import DefaultApiService, { BaseHasTimeEntity, HasStateMachineVersionEntity } from '@/libs/api-request'
import { DataApiResult, PagerQueryBean } from '@/libs/http-request'

/**
 * 团队小组成员
 */
export interface TeamGroupMember extends BaseHasTimeEntity, HasStateMachineVersionEntity{

    /**
     * 主键id
     */
    id?: number;

    /**
     * 版本号
     */
    version?: number;

    /**
     * 小组id
     */
    groupId?: number;

    /**
     * 团队用户id
     */
    teamUserId?: number;

    /**
     * 团队小组
     */
    teamGroupName?: string;

    /**
     * 团队内名称
     */
    nameAtTeam?: string;

}

class TeamGroupMemberApi extends DefaultApiService<TeamGroupMember> {
  constructor () {
    super('/api/team-group-member')
  }

  teamGroupMemberList (query: PagerQueryBean) {
    return this.queryByQueryBean('teamGroupMemberList', query)
  }

  queryTeamGroupMember (groupId: number): Promise<DataApiResult<TeamGroupMember[]>> {
    return this.requestList('queryTeamGroupMember/' + groupId, null, 'GET')
  }

  /**
   * 保存组成员
   * @param data = { teamUserList:团队成员,groupId:小组 }
   * @returns
   */
  saveGroupMember (data: any) {
    return this.request('saveGroupMember', data, 'POST')
  }

  deleteTeamGroupMember (groupId: number, teamUserId: number) {
    return this.request('deleteTeamGroupMember/' + groupId + '/' + teamUserId, null, 'GET')
  }
}

export default new TeamGroupMemberApi()
