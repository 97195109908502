import DefaultApiService, { BaseHasTimeEntity, HasStateMachineVersionEntity } from '@/libs/api-request'
import { PagerQueryBean } from '@/libs/http-request'

/**
 * 团队小组
 */
export interface TeamGroup extends BaseHasTimeEntity, HasStateMachineVersionEntity{

  id?: number;

  version?: number;

  // 团队id
  team?: number;

  // 创建者ID
  creator?: number;

  // 修改者ID
  mender?: number;

  // 创建者名称
  creatorName?: string;

  // 修改者名称
  menderName?: string;

  /** 创建时间 */
  createTime?: string;

  /** 修改时间 */
  modifyTime?: string;

  // 名称
  name?: string;

  // 归属团队
  ownTeam?: number;

  // 备注
   memo?: string;

  // 团队名称
   teamName?: string;

}

class TeamGroupApi extends DefaultApiService<TeamGroup> {
  constructor () {
    super('/api/team-group')
  }

  /**
   * 团队小组接口
   * @param query 查询bean对象
   */
  queryTeamGroupList (query: PagerQueryBean) {
    return this.queryByQueryBean('team-group-list', query)
  }

  /**
   * 根据teamId查询团队小组
   * @param teamId 团队id
   */
  teamGroupList () {
    return this.request('teamGroupList', null, 'GET')
  }
}

export default new TeamGroupApi()
