
import { TeamUse } from '@/api/team-user'
import { showModal } from '@/libs/modal-helper'
import modal from './add-members-modal.vue'

/**
  * 显示创建团队的弹出对话框
  * @param userId
  * @param createMode 为真时是创建模式,否则是加入模式
  * @returns
  */
export default function showAddGroupMemberModal (groupId: number, groupName: string): Promise<Array<TeamUse>> {
  return new Promise<Array<TeamUse>>((resolve, reject) => {
    showModal<Array<TeamUse>>(modal, {
      props: {
        groupId: groupId,
        groupName: groupName
      }
    }, true, (teams) => {
      resolve(teams)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
