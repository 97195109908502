
import BaseTablePanel from '@/frame/share/table-panel'
import { Component } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import tableConfig from './index-table-config'
import { PagerQueryBean, PageListData, PageDataApiResult } from '@/libs/http-request'
import _ from 'lodash'
import teamGroupApi, { TeamGroup } from '@/api/team-group'
import teamGroupMemberApi, { TeamGroupMember } from '@/api/team-group-member'
import { TeamUse } from '@/api/team-user'
import AddTeamGroupModal from './add-team-group-modal.vue'
import { PageInfo } from '@/libs/api-request'
import showAddGroupMemberModal from './add-members-modal'
import { getterUserInfo } from '@/store'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: ['-modifyTime'],
  name: undefined,
  dateRage: []
}

@Component({
  name: 'teamGroupDataList',
  components: { ExpendSearchPanel, TablePanel, AddTeamGroupModal }
})
export default class TeamGroupDataList extends BaseTablePanel<TeamGroup> {
  // 当前状态
  loading=false
  /// 表格定义
  tableConfig=tableConfig

  childData: Array<TeamUse> = []

  /// 查询数据
  queryData: PagerQueryBean=_.cloneDeep(defaultQuery)

  pageData: PageListData<TeamGroup>={
    items: [],
    total: 0,
    pageSize: 10
  }

  /**
   * 判断用户的团队管理权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canAddUser
  }

  /**
   * 工具栏按钮事件
   */
  protected doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
  }

  /**
   * 从服务器拉取数据
   */
  protected onPullDataListFromServer (pagerQueryBean: PagerQueryBean): Promise<PageDataApiResult<TeamGroup>> {
    return teamGroupApi.queryTeamGroupList(pagerQueryBean)
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)
    if (!_.isEqual(newQuery, this.queryData)) {
      this.$nextTick(() => {
        this.queryData = newQuery
        this.doPullDataList()
      })
    }
  }

  /**
   * 页面数据拉取
   */
  protected doPullDataList (pageInfo?: PageInfo) {
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    const that = this

    this.pullDataList(pageInfo).then(response => {
      that.pageData = response;
      (that.$refs.tablePanel as any).setCurPage(pageInfo?.curPage)
    })
  }

  /**
    * 申请时间
    */
  onDateRangeChange (value: Array<string>) {
    if (value) {
      this.queryData.dateRage = value
    }
  }

  /**
   * 加载数据
   */
  mounted () {
    this.$nextTick(() => {
      this.doPullDataList()
    })
  }

  /**
   * 列表展开行
   */
  loadContentMethod (item: any): Promise<void> {
    return new Promise((resolve, reject) => {
      teamGroupMemberApi.queryTeamGroupMember(item.row.id)
        .then(res => {
          item.row.childData = res.data
          resolve()
        })
        .catch((err: Error) => {
          this.loading = false
          this.$Notice.error({
            title: '提示',
            desc: err.message
          })
          reject(err)
        })
    })
  }

  /// 删除组成员
  onDelGroupMember (item: TeamGroupMember, row: TeamGroup) {
    this.$Modal.confirm(
      {
        title: '提示',
        content: '确定要删除小组成员：' + item.nameAtTeam + ' 吗？',
        onOk: () => {
          this.deleteTeamGroupMember(row.id!, item.teamUserId!, row)
        }
      }
    )
  }

  /**
   * 添加团队小组
   */
  addTeamGroup () {
    const modal = this.$refs.addTeamGroupModalRef as AddTeamGroupModal
    modal.showDlg()
  }

  successData () {
    this.doPullDataList()
  }

  /**
   * 添加组成员
   */
  addGroupMember (row: TeamGroup) {
    showAddGroupMemberModal(row.id!, row.name!)
      .then((members) => {
        const data = members.map((item) => {
          const member: TeamGroupMember = {
            teamUserId: item.id,
            nameAtTeam: item.nameAtTeam
          }
          return member
        });
        (row as any).childData.push(...data)
        this.$forceUpdate()
      })
  }

  deleteRow (row: TeamGroup) {
    this.$Modal.confirm(
      {
        title: '提示',
        content: '删除小组以及小组成员也会一并删除，确定要删除吗？',
        onOk: () => {
          this.deleteData([row.id!])
        }
      }
    )
  }

  deleteData (ids: Array<number>) {
    teamGroupApi.deleteItemByIds(ids)
      .then(res => {
        this.$Notice.success({ title: '提示', desc: '删除成功' })
        this.doPullDataList()
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '删除记录失败',
          desc: err.message
        })
      })
  }

  /**
   * 删除组成员
   */
  deleteTeamGroupMember (groupId: number, teamUserId: number, row: any) {
    teamGroupMemberApi.deleteTeamGroupMember(groupId, teamUserId)
      .then(res => {
        this.$Notice.success({ title: '提示', desc: '删除成功' })
        row.childData = (row.childData as Array<any>).filter(item =>
          item.teamUserId !== teamUserId
        )
        // 更新视图
        this.$forceUpdate()
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '删除记录失败',
          desc: err.message
        })
      })
  }
}

