
// 表单数据校验定义
export default {

  // 名称
  name: [
    { required: true, message: '不允许为空', trigger: 'blur' }
  ],

  // 归属团队
  ownTeam: [
    { required: true, type: 'number', message: '不允许为空', trigger: 'blur' }
  ],

  // 备注
  memo: [
    { required: true, message: '不允许为空', trigger: 'blur' }
  ]

}
